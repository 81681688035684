<template>
  <div class="referral-edit-sidebar">
    <div class="referral-edit-sidebar--content">
      <div class="current-details">
        <p>
          <b>Vehicles:</b>
          {{ formattedVehicles }}
        </p>
        <p>
          <b>Drivers:</b>
          {{ driverCount }}
        </p>
        <p>
          <b>Passengers:</b>
          {{ passengerCount }}
        </p>
      </div>
      <v-divider class="divider" />
      <v-form ref="details-form" class="details-edit">
        <v-layout column>
          <v-layout
            v-for="(requiredVehicle, index) in referralRequiredVehicles"
            :key="index"
            row
          >
            <v-layout column class="vehicle-type-label">
              {{ requiredVehicle.vehicleType.label }}
            </v-layout>
            <v-layout column style="width: 80px; margin-right: 30px">
              <CRInput
                v-model.number="requiredVehicle.quantity"
                label="Vehicles"
                flat
                solo
                type="number"
                min="0"
                :rules="[(val) => val >= 0 || 'Must be at least 0']"
              />
            </v-layout>
          </v-layout>
        </v-layout>
        <CRInput
          v-model.number="referralDriverCount"
          label="Drivers"
          class="w-80 margin-r-10"
          flat
          solo
          type="number"
          min="0"
          :rules="[(val) => val >= 0 || 'Must be at least 0']"
        />
        <CRInput
          v-model.number="referralPassengerCount"
          label="Passengers"
          class="w-80"
          flat
          solo
          type="number"
          min="1"
          :rules="[(val) => val > 0 || 'Must be greater than 0']"
        />
      </v-form>
      <v-divider />
      <div class="amount-edit">
        <p>
          <b>Original Amount:</b>
          {{ currencyFilter(referral.referralAmount) }}
        </p>
        <v-form ref="amount-form">
          <CRInput
            id="amount-input"
            :value="formattedAmount"
            :disabled="offerAtSameAmount"
            type="number"
            prefix="$"
            min="0"
            solo
            flat
            validate-on-blur
            :rules="[
              () => validateAmountNotEmpty() || 'Amount is required',
              () =>
                validateAmountGreaterThanZero() ||
                'Amount must be greater than 0',
            ]"
            @blur="updateNewAmount"
          >
            <template #label>
              <span class="new-amount-label mb-1">
                New Amount
                <a
                  :href="affiliatePageUrl"
                  target="_blank"
                  class="affiliate-page-link"
                >
                  Affiliates Page
                  <CRIcon
                    view-box="0 0 24 24"
                    width="15"
                    height="15"
                    class="margin-l-1"
                  >
                    open_in_new
                  </CRIcon>
                </a>
              </span>
            </template>
          </CRInput>
        </v-form>
        <v-checkbox
          v-model="offerAtSameAmount"
          class="mt-0 pt-0 pb-0 max-h-20 min-h-20"
          hide-details
        >
          <template #label>
            <span class="font-size-10 text-color-gray">
              Offer at Same Amount
            </span>
          </template>
        </v-checkbox>
        <CRRichText
          v-if="!offerAtSameAmount"
          class="margin-t-7"
          :note="paymentNotes"
          label="Payment Notes"
          multi-line
          placeholder="Payment notes for you and the customer."
          @htmlchange="updatePaymentNoteHtml"
          @textchange="updatePaymentNoteText"
        />
        <CRRichText
          v-if="!offerAtSameAmount"
          class="margin-t-7"
          :note="officeNotes"
          label="Office Notes"
          multi-line
          placeholder="Payment notes for internal use only (customers will not see this)."
          @htmlchange="updateOfficeNoteHtml"
          @textchange="updateOfficeNoteText"
        />
      </div>
    </div>
    <div class="cr-sidebar-dialog--button-spacer" />
    <div>
      <CRButton
        id="referral-edit-save-btn"
        :loading="submitting"
        :disabled="submitting"
        class="referral-edit-sidebar--action-btn"
        color="primary"
        @click="submit"
      >
        Send Edited Referral
      </CRButton>
    </div>
  </div>
</template>

<script>
import { pluralize } from '@/utils/string'
import { currencyFilter, currencyToNumber } from '@/utils/currency'
import { deepClone } from '@/utils/deepClone'
import { baseUrl } from '@/utils/env'
import { EventBus } from '@/utils/event-bus'
import { mapActions } from 'vuex'

export default {
  props: {
    referral: {
      type: Object,
      required: true,
    },
    reservationId: {
      type: Number,
      required: true,
    },
    driverCount: {
      type: Number,
      required: true,
    },
    passengerCount: {
      type: Number,
      required: true,
    },
    requiredVehicles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      referralRequiredVehicles: [],
      referralDriverCount: null,
      referralPassengerCount: null,
      newAmount: null,
      offerAtSameAmount: false,
      paymentNote: null,
      paymentNoteHtml: null,
      officeNote: null,
      officeNoteHtml: null,
      submitting: false,
    }
  },
  computed: {
    formattedVehicles() {
      return this.requiredVehicles
        ?.map(
          (v) => `${v.quantity} ${pluralize(v.quantity, v.vehicleType.label)}`
        )
        .join(', ')
    },
    affiliatePageUrl() {
      return `https://${this.baseUrl(null)}/ci/affiliates/${
        this.referral.companyId
      }`
    },
    formattedAmount() {
      return this.newAmount ? this.newAmount.toFixed(2) : ''
    },
  },
  watch: {
    offerAtSameAmount(newValue) {
      if (newValue) {
        this.newAmount = this.referral.referralAmount
        this.$refs['amount-form'].validate()
      } else {
        this.newAmount = null
      }
    },
  },
  mounted() {
    this.referralRequiredVehicles = deepClone(this.referral.requiredVehicles)
    for (const requiredVehicle of this.requiredVehicles) {
      if (
        !this.referralRequiredVehicles.some(
          (rv) => rv.vehicleType.id === requiredVehicle.vehicleType.id
        )
      ) {
        this.referralRequiredVehicles.push({
          quantity: 0,
          vehicleType: requiredVehicle.vehicleType,
        })
      }
    }
    this.referralDriverCount = this.referral.requiredDrivers
    this.referralPassengerCount = this.referral.referralPassengerCount
  },
  methods: {
    baseUrl,
    currencyFilter,
    ...mapActions({ showAlert: 'app/showAlert' }),
    updateNewAmount(e) {
      this.newAmount = currencyToNumber(e.target.value)
      if (this.newAmount === this.referral.referralAmount) {
        this.offerAtSameAmount = true
      }
    },
    validateAmountNotEmpty() {
      return this.newAmount != null
    },
    validateAmountGreaterThanZero() {
      return this.newAmount > 0
    },
    updatePaymentNoteText(note) {
      this.paymentNote = note
    },
    updatePaymentNoteHtml(html) {
      this.paymentNoteHtml = html
    },
    updateOfficeNoteText(note) {
      this.officeNote = note
    },
    updateOfficeNoteHtml(html) {
      this.officeNoteHtml = html
    },
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    async submit() {
      this.submitting = true
      if (
        !this.$refs['amount-form'].validate() ||
        !this.$refs['details-form'].validate()
      ) {
        this.submitting = false
        return
      }
      const newReferralVehicles = this.referralRequiredVehicles.map((rv) => {
        return {
          reservationId: this.referral.reservationId,
          quantity: rv.quantity,
          vehicleTypeId: rv.vehicleType.id,
        }
      })
      const offerParams = {
        reservationId: this.reservationId,
        referralId: this.referral.reservationId,
        newAmount: this.newAmount,
        newRequiredDrivers: this.referralDriverCount,
        newReferralPassengerCount: this.referralPassengerCount,
        newReferralVehicles,
        paymentNotes: {
          note: this.paymentNote,
          html: this.paymentNoteHtml,
        },
        officeNotes: {
          note: this.officeNote,
          html: this.officeNoteHtml,
        },
        companyId: this.referral.companyId,
      }
      const response = await this.$store.dispatch(
        'reservations/reofferReferral',
        offerParams
      )
      if (response?.data?.successful) {
        setTimeout(() => {
          this.showAlert({
            type: 'success',
            message: `Successfully reoffered edited referral.`,
          })
        }, 2000)
      } else {
        setTimeout(() => {
          this.showAlert({
            type: 'error',
            message: `Error offering edited referral.`,
          })
        }, 2000)
      }
      EventBus.$emit('refresh-detail')
      EventBus.$emit('refresh-reservation-detail')
      EventBus.$emit('refresh-reservation-payments')
      this.submitting = false
      this.close()
    },
  },
}
</script>

<style lang="scss" scoped>
.referral-edit-sidebar {
  display: flex;
  flex-direction: column;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
  }
}
.divider {
  margin-top: 1.6em;
}
.details-edit {
  margin-bottom: 1.6em;
  display: flex;
  flex-direction: row;
}
.vehicle-type-label {
  min-width: fit-content;
  font-weight: bold;
  justify-content: center;
  margin-right: 40px;
  padding-top: 15px;
  width: 75px;
}
.new-amount-label {
  display: flex;
  justify-content: space-between;
}
.affiliate-page-link {
  text-decoration: none;
  display: flex;
  align-items: center;
}
p {
  margin-bottom: 10px;
}
::v-deep .v-input__slot {
  margin-bottom: 5px;
}
::v-deep .v-text-field.v-text-field--solo .v-text-field__details {
  margin-bottom: 2px;
}
::v-deep .v-input--hide-details .v-input__control .v-input__slot {
  min-height: 0px;
}
</style>
